@import url('https://fonts.googleapis.com/css?family=Roboto|Roboto+Mono&display=swap');
@import 'variables.scss';

html,
body,
#root {
    margin: 0px;
    font-family: $normal;
    font-size: 1em;
    width: 99vw;
    height: 100vh;
    background-color: $backgroundColor;
    color: $text;
}

a {
    color: $accent;
}

h1,
h2,
h3,
h4 {
    color: $primary;
}
